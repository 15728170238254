import React, { useEffect } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { Container, Grid, Typography } from "@material-ui/core";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";

import Textfield from "./FormsUI/Textfield";
import Button from "./FormsUI/Button";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import Switch from "@mui/material/Switch";
import { useState } from "react";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Table from "@material-ui/core/Table";
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from "@material-ui/core/Paper";
import CloseIcon from '@mui/icons-material/Close';
import Tooltip from '@material-ui/core/Tooltip';
import date from 'date-and-time';
const INITIAL_FORM_STATE_TEST = {

};

const exchangeOptions = [
  "ByBit",
  "Bitget",
  "Gate.io",
];
const boolOptions = [
  "True",
  "False"
];
const FORM_VALIDATION = Yup.object().shape({
  api: Yup.string().required("Required"),
  secret: Yup.string().required("Required"),
  api_pass: Yup.string().optional()
});
const useStyles = makeStyles((theme) => ({
  formWrapper: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(8),
  },
}));

const App = () => {

  useEffect(() => {
    fetchItems();
  }, []);
  const [items, setItems] = useState([]);

  const [exchange, setExchange] = useState(exchangeOptions[0]);
  const [network, setNetwork] = useState(boolOptions[0]);

  const handleSideChange = (event) => {
    setExchange(String(event.target.value.toString()));
    // console.log(event.target.value);
  };
  const handleNetworkChange = (event) => {
    setNetwork(String(event.target.value.toString()));
    // console.log(event.target.value);
  };
  const classes = useStyles();
  const fetchItems = async () => {
    const data = await fetch(`${process.env.REACT_APP_API_ADDRESS}/api`)
      .then(async function (data) {
        const items = await data.json();
        setItems(items);
      })
      .catch(function (err) {
        console.log(`Error Fetching BingX API ${err}`);
      });
    // console.log(data);

  };
  useEffect(() => {
    fetchItems();
  }, []);

  return (
    <Grid container>
      <Grid item xs={12}>
        <Container maxWidth="md">
          <div className={classes.formWrapper}>
            <Formik
              initialValues={{
                ...INITIAL_FORM_STATE_TEST,
              }}

              validationSchema={FORM_VALIDATION}
              onSubmit={async (values, actions) => {
                values['testnet'] = network;
                values['exchange'] = exchange;
                console.log(values);
                console.log(process.env.REACT_APP_API_ADDRESS)
                const response = await axios
                  .post(`${process.env.REACT_APP_API_ADDRESS}/api`, values)
                  .then(function (res) {
                    console.log(res)
                    //if (res.data.success == false) {
                      fetchItems();
                    alert("API Updated");
                    //}
                  })
                  .catch(function (error) {
                    console.error(error.response.data);
                    alert("Failed To Update API");
                  });
                setTimeout(() => {
                  actions.setSubmitting(false);
                }, 1000);
              }}
            >
              <Form>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography variant="h4" component="h2">
                      Insert API
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl sx={{ width: "100%" }} name="exchange">
                      <InputLabel id="exchange">Exchange</InputLabel>
                      <Select
                        defaultValue="Choose"
                        sx={{ width: "100%" }}
                        name="exchange"
                        label="Exchange"
                        value={exchange}
                        onChange={handleSideChange}
                      >
                        {exchangeOptions.map((text) => {
                          return (
                            <MenuItem value={text.toString()}>{text}</MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <Textfield name="api" label="API Key" />
                  </Grid>
                  <Grid item xs={6}>
                    <Textfield name="secret" label="API SECRET" />
                  </Grid>
                  <Grid item xs={6}>
                    <Textfield name="passphrase" label="API Passphrase" />
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl sx={{ width: "100%" }} name="Test Net">
                      <InputLabel id="testnet">Test Network</InputLabel>
                      <Select
                        defaultValue="Choose"
                        sx={{ width: "100%" }}
                        name="side"
                        label="Side"
                        value={network}
                        onChange={handleNetworkChange}
                      >
                        {boolOptions.map((text) => {
                          return (
                            <MenuItem value={text.toString()}>{text}</MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <Button type="submit">Update API</Button>
                  </Grid>

                </Grid>
              </Form>
            </Formik>

          </div>
          <Grid item xs={12} >
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 200 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="left">Exchange&nbsp;</TableCell>
                    <TableCell align="middle">API&nbsp;</TableCell>
                    <TableCell align="right">testnet&nbsp;</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {items.map((row) => (
                    < TableRow
                      key={row.id}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell align="left">{row.exchange}</TableCell>
                      <TableCell align="middle">{row.api.slice(0, 10) + "..."}</TableCell>
                      <TableCell align="right">{row.testnet}</TableCell>

                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer >
          </Grid>
        </Container>
      </Grid>

    </Grid >
  );
};

export default App;
