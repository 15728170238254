import Header from './components/Header';
import Orders from './components/Orders';
import Positions from './components/Positions';
import Api from './components/Api';

import './App.css'
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Form from './components/Form';
import SideMenu from "./components/SideMenu";
import { useState } from "react";

function App() {

  const [inactive, setInactive] = useState(false);

  return (
    <div className="App">
      <Router>
        <Header />
        <SideMenu
          onCollapse={(inactive) => {
            console.log(inactive);
            setInactive(inactive);
          }}
        />

        <div className={`container ${inactive ? "inactive" : ""}`}>
          <Switch>
            <Route exact path={"/"} />
            <Route exact path={"/orders"} exact component={Orders} />              
            <Route exact path={"/positions"} exact component={Positions} />
            <Route exact path={"/api"} exact component={Api} />

            <Route path={"/inputs"} exact component={Form} />
          </Switch>
        </div>
      </Router>
    </div>
  );
}

export default App;