import React, { useEffect } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { Container, Grid, Typography } from "@material-ui/core";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";

import Textfield from "./FormsUI/Textfield";
import Button from "./FormsUI/Button";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import Switch from "@mui/material/Switch";
import { useState } from "react";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

const INITIAL_FORM_STATE_TEST = {

};

const sides = [
  "Buy",
  "Sell"
];
const FORM_VALIDATION = Yup.object().shape({
  // symbol: Yup.string().required("Required"),
  qty: Yup.string().required("Required"),
  // side: Yup.string().required("Required"),
  tp: Yup.number().min(0).max(100).required(),
  sl: Yup.number().min(0).max(100).required(),
  time: Yup.date().required(),
});
const useStyles = makeStyles((theme) => ({
  formWrapper: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(8),
  },
}));
const exchangeOptions = [
  "ByBit",
  "Bitget",
  "Gate.io",
];
const tpslTYPE = [
  "Market",
  "Limit Order",
];
const tpslCalculation = [
  "Start Time",
  "Order Fill"
];
const App = () => {


  const [exchange, setExchange] = useState([]);
  const [symbol, setSymbol] = useState([]);
  const [sym, setSym] = useState([]);
  const [ByBitSymbols, setByBitSymbols] = useState([]);
  const [BitGetSymbols, setBitgetSymbols] = useState([]);
  const [GateIOSymbols, setGateIOSymbols] = useState([]);
  const [BingXSymbols, setBingXSymbols] = useState([]);
  const [side, setSide] = useState(sides[0]);
  const [TPSLTYPE, setTPSLTYPE] = useState(tpslTYPE[0]);
  const [TPSLCALCULATION, setTPSLCALCULATION] = useState(tpslCalculation[0]);
  const handleSymbolChange = (event) => {
    setSymbol(String(event.target.value.toString()));
  };
  const handleTPSLTYPEChange = (event) => {
    setTPSLTYPE(String(event.target.value.toString()));
    // console.log(event.target.value);
  };
  const handleTPSLCalculationChange = (event) => {
    setTPSLCALCULATION(String(event.target.value.toString()));
    // console.log(event.target.value);
  };
  const handleExchangeChange = (event) => {
    setSymbol('')

    if (event.target.value.toString() == "ByBit") {
      setSym(ByBitSymbols);
    }
    if (event.target.value.toString() == "Bitget") {
      setSym(BitGetSymbols);
    }
    if (event.target.value.toString() == "Gate.io") {
      setSym(GateIOSymbols);
    }
    if (event.target.value.toString() == "BingX") {
      console.log(`Setting BingX exchange Symbols`);
      setSym(BingXSymbols);
    }
    setExchange(String(event.target.value.toString()));
  };
  const handleSideChange = (event) => {
    setSide(String(event.target.value.toString()));
  };
  const classes = useStyles();
  const fetchItems = async () => {
    // try {
    await fetch(`${process.env.REACT_APP_API_ADDRESS}/symbol/ByBit`)
      .then(async function (res) {
        const items = await res.json();
        const temp = [];
        items.forEach(element => {
          temp.push(String(element.symbol))
        });
        setByBitSymbols(temp);
        setSym(ByBitSymbols);
      })
      .catch(function (err) {
        console.log(`Error Fetching ByBit Symbols ${err}`);
      });

    await fetch(`${process.env.REACT_APP_API_ADDRESS}/symbol/Bitget`)
      .then(async function (res) {
        const BitGetItems = await res.json();
        const temp1 = [];
        BitGetItems.forEach(element => {
          temp1.push(String(element.symbol))
        });
        setBitgetSymbols(temp1);
      })
      .catch(function (err) {
        console.log(`Error Fetching ByBit Symbols ${err}`);
      });
    await fetch(`${process.env.REACT_APP_API_ADDRESS}/symbol/Gate.io`)
      .then(async function (res) {
        const BitGetItems = await res.json();
        const temp1 = [];
        BitGetItems.forEach(element => {
          temp1.push(String(element.symbol))
        });
        setGateIOSymbols(temp1);
      })
      .catch(function (err) {
        console.log(`Error Fetching Gate.io Symbols ${err}`);
      });
    await fetch(`${process.env.REACT_APP_API_ADDRESS}/symbol/BingX`)
      .then(async function (res) {
        const BitGetItems = await res.json();
        const temp1 = [];
        BitGetItems.forEach(element => {
          temp1.push(String(element.symbol))
        });
        console.log(`BingX Symbol Fetched`)
        setBingXSymbols(temp1);
      })
      .catch(function (err) {
        console.log(`Error Fetching BingX Symbols ${err}`);
      });
  };
  useEffect(() => {
    fetchItems();
  }, []);

  return (
    <Grid container>
      <Grid item xs={12}>
        <Container maxWidth="md">
          <div className={classes.formWrapper}>
            <Formik
              initialValues={{
                ...INITIAL_FORM_STATE_TEST,
              }}

              validationSchema={FORM_VALIDATION}
              onSubmit={async (values, actions) => {
                values['symbol'] = symbol;
                values['side'] = side;
                values['exchange'] = exchange;
                values['tp_sl_type'] = TPSLTYPE;
                values['tp_sl_calculation'] = TPSLCALCULATION;
                console.log(values);
                const response = await axios
                  .post(`${process.env.REACT_APP_API_ADDRESS}/order`, values)
                  .then(function (res) {
                    if (res.data.success == false) {
                      alert("Order Failed to Add");
                    }
                    else {
                      alert("Order Added");
                    }
                  })
                  .catch(function (error) {
                    console.log(error.response.data);
                    alert(error.response.data);
                  });
                setTimeout(() => {
                  actions.setSubmitting(false);
                }, 1000);
              }}
            >
              <Form>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography variant="h4" component="h2">
                      Place New Order
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl sx={{ width: "100%" }} name="exchange">
                      <InputLabel id="exchange">Exchange</InputLabel>
                      <Select
                        defaultValue="Choose"
                        sx={{ width: "100%" }}
                        name="exchange"
                        label="Exchange"
                        value={exchange}
                        onChange={handleExchangeChange}
                      >
                        {exchangeOptions.map((text) => {
                          return (
                            <MenuItem value={text.toString()}>{text}</MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl sx={{ width: "100%" }} name="symbol">
                      <InputLabel id="symbol" name="symbol">Symbol</InputLabel>
                      <Select
                        defaultValue="Choose"
                        sx={{ width: "100%" }}
                        name="symbol"
                        label="Symbol"
                        value={symbol}
                        onChange={handleSymbolChange}
                      >
                        {sym.map((text) => {
                          return (
                            <MenuItem value={text.toString()}>{text}</MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <Textfield name="qty" label="Amount" />
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl sx={{ width: "100%" }} name="side">
                      <InputLabel id="symbol">Symbol</InputLabel>
                      <Select
                        defaultValue="Choose"
                        sx={{ width: "100%" }}
                        name="side"
                        label="Side"
                        value={side}
                        onChange={handleSideChange}
                      >
                        {sides.map((text) => {
                          return (
                            <MenuItem value={text.toString()}>{text}</MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={6}>
                    <FormControl sx={{ width: "100%" }} name="side">
                      <InputLabel id="symbol">TP SL Type</InputLabel>
                      <Select
                        defaultValue="Choose"
                        sx={{ width: "100%" }}
                        name="tpslType"
                        label="TP SL Type"
                        value={TPSLTYPE}
                        onChange={handleTPSLTYPEChange}
                      >
                        {tpslTYPE.map((text) => {
                          return (
                            <MenuItem value={text.toString()}>{text}</MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={6}>
                    <FormControl sx={{ width: "100%" }} name="side">
                      <InputLabel id="symbol">TP SL Calculation</InputLabel>
                      <Select
                        defaultValue="Choose"
                        sx={{ width: "100%" }}
                        name="tpslCal"
                        label="TP SL Calculation"
                        value={TPSLCALCULATION}
                        onChange={handleTPSLCalculationChange}
                      >
                        {tpslCalculation.map((text) => {
                          return (
                            <MenuItem value={text.toString()}>{text}</MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Grid>


                  <Grid item xs={6}>
                    <Textfield name="tp" label="Take Profit" />
                  </Grid>
                  <Grid item xs={6}>
                    <Textfield name="sl" label="Stop Loss" />
                  </Grid>                  <Grid item xs={6}>
                    <Textfield name="time" label="Time YYYY-MM-DD HH:mm:ss:SSS" />
                  </Grid>
                  <Grid item xs={12}>
                    <Button type="submit">Place Trade</Button>
                  </Grid>

                </Grid>
              </Form>
            </Formik>
          </div>
        </Container>
      </Grid>
    </Grid >
  );
};

export default App;
