import React, { useEffect, useState } from 'react';
import Table from "@material-ui/core/Table";
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from "@material-ui/core/Paper";
import CloseIcon from '@mui/icons-material/Close';
import { makeStyles } from "@material-ui/core";
import Tooltip from '@material-ui/core/Tooltip';
import date from 'date-and-time';

import axios from 'axios';


function Positions() {
  useEffect(() => {
    fetchItems();
  }, []);


  const [items, setItems] = useState([]);

  const fetchItems = async () => {
    const data = await fetch(`${process.env.REACT_APP_API_ADDRESS}/positions`)
      .then(async function (data) {
        const items = await data.json();
        setItems(items);
      })
      .catch(function (err) {
        console.log(`Error Fetching BingX Symbols ${err}`);
      });
    // console.log(data);

  };
  const useStyles = makeStyles({
    close: {

      "&:hover":
      {
        color: "red",
      }

    }
  });
  const classes = useStyles();
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>

            <TableCell align="middel">Id</TableCell>
            <TableCell align="middle">TIME&nbsp;</TableCell>
            <TableCell align="middle">SYMBOL&nbsp;</TableCell>
            <TableCell align="middle">SIDE&nbsp;</TableCell>
            <TableCell align="middle">QTY&nbsp;</TableCell>
            <TableCell align="middle">PRICE&nbsp;</TableCell>
            <TableCell align="middle">STATUS&nbsp;</TableCell>
            <TableCell align="middle">TP&nbsp;</TableCell>
            <TableCell align="middle">SL&nbsp;</TableCell>
            <TableCell align="middle">COST(USDT)&nbsp;</TableCell>
            <TableCell align="middle">TP SL TYPE&nbsp;</TableCell>
            <TableCell align="middle">TP SL Calculation&nbsp;</TableCell>
            <TableCell align="middle">TP Price&nbsp;</TableCell>
            <TableCell align="middle">SL Price&nbsp;</TableCell>
            <TableCell align="middle">Exchange&nbsp;</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {items.map((row) => (
            console.log(`Original Date ${row.time}`),
            console.log(`UTC Conversion ${new Date(row.time).toLocaleString('en-US', { timeZone: "Asia/Tel_Aviv" })}`),
            < TableRow
              key={row.name}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >

              <TableCell align="middle">{row.id}</TableCell>
              <TableCell align="middle">{new Date(row.time).toLocaleString('en-US', { hourCycle: 'h23', timeZone: "Asia/Tel_Aviv" }) + `.${new Date(row.time).getMilliseconds()}`}</TableCell>
              <TableCell align="middle">{row.symbol}</TableCell>
              <TableCell align="middle">{row.side}</TableCell>
              <TableCell align="middle">{row.qty}</TableCell>
              <TableCell align="middle">{row.price}</TableCell>
              <TableCell align="middle">{row.status}</TableCell>
              <TableCell align="middle">{row.tp}</TableCell>
              <TableCell align="middle">{row.sl}</TableCell>
              <TableCell align="middle">{row.cost}</TableCell>
              <TableCell align="middle">{row.tp_sl_type}</TableCell>
              <TableCell align="middle">{row.tp_sl_calculation}</TableCell>
              <TableCell align="middle">{row.tp_price}</TableCell>
              <TableCell align="middle">{row.sl_price}</TableCell>
              <TableCell align="middle">{row.exchange}</TableCell>
              <TableCell align="middle">
                <Tooltip title="Delete">
                  <CloseIcon aria-label="delete" className={`head ${classes.close}`}
                    onClick={async () => {
                      const response = await fetch(`${process.env.REACT_APP_API_ADDRESS}/order/` + row.id, { method: 'DELETE' })
                        .then(function (res) {
                          // console.log("Order Deletion Request Sent", row.id)
                          if (res.data.success == true) {
                            // alert("Bot have been Killed")
                            window.location.reload(false)
                          }
                        })
                        .catch(function (err) {
                          fetchItems();
                          // console.error('There was an error!', err);
                        });
                    }}
                  >
                  </CloseIcon>
                </Tooltip>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer >
  );



}
export const formatTimeByOffset = (dateString, offset) => {
  // Params:
  // How the backend sends me a timestamp
  // dateString: on the form yyyy-mm-dd hh:mm:ss
  // offset: the amount of hours to add.

  // If we pass anything falsy return empty string
  if (!dateString) return ''
  if (dateString.length === 0) return ''

  // Step 1: Parse the backend date string

  // Get Parameters needed to create a new date object
  const year = dateString.slice(0, 4)
  const month = dateString.slice(5, 7)
  const day = dateString.slice(8, 10)
  const hour = dateString.slice(11, 13)
  const minute = dateString.slice(14, 16)
  const second = dateString.slice(17, 19)

  // Step: 2 Make a JS date object with the data
  const dateObject = new Date(`${year}-${month}-${day}T${hour}:${minute}:${second}`)

  // Step 3: Get the current hours from the object
  const currentHours = dateObject.getHours()

  // Step 4: Add the offset to the date object
  dateObject.setHours(currentHours + offset)

  // Step 5: stringify the date object, replace the T with a space and slice off the seconds.
  const newDateString = dateObject
    .toISOString()
    .replace('T', ' ')
    .slice(0, 16)

  // Step 6: Return the new formatted date string with the added offset
  return `${newDateString}`
}
export default Positions;